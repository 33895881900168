import React, { useState, useEffect, useContext, useCallback } from 'react';
import * as S from './AnswerContainer.styles';
import { Builder } from '@builder.io/react';
import FunnelContext from '../../../context/FunnelContext';
import useLocalStorage from '../../../hooks/useLocalStorage';

const AnswerContainer = props => {
  const {
    propertyKey,
    propertyValue,
    children,
    attributes,
    checkQueryParams = false
  } = props;

  const { answers } = useContext(FunnelContext);

  const [show, setShow] = useState(false);
  const [checked, setChecked] = useState(false);
  const [interval2, setInterval2] = useState(null);

  const [answers2] = useLocalStorage('answers', {});

  const checkProperty = useCallback(() => {
    let newVal = show;
    const params = new URLSearchParams(window.location.search);
    const queryPropertyValue = params.get(propertyKey);

    if (
      Object.keys(answers).includes(propertyKey) ||
      Object.keys(answers2).includes(propertyKey) ||
      (checkQueryParams && params.has(propertyKey))
    ) {
      if (
        answers[propertyKey] === propertyValue ||
        answers2[propertyKey] === propertyValue ||
        (checkQueryParams && propertyValue === queryPropertyValue)
      ) {
        newVal = true;
      } else {
        newVal = false;
      }
    } else {
      newVal = false;
    }
    if (newVal !== show) {
      setTimeout(() => {
        setShow(newVal);
      }, 200);
    }
  }, [answers, answers2, checkQueryParams, propertyKey, propertyValue, show]);

  useEffect(() => {
    if (!checked) {
      setInterval2(
        setInterval(() => {
          checkProperty();
        }, 1)
      );
    }
    return () => {
      setChecked(true);
      if (show) {
        clearInterval(interval2);
      }
    };
  }, [
    answers,
    show,
    setShow,
    propertyKey,
    propertyValue,
    checked,
    setChecked,
    answers2,
    interval2,
    setInterval2,
    checkProperty
  ]);

  const noChildren = () => {
    return <S.NoChildren>insert components here</S.NoChildren>;
  };

  const Container = () => {
    return (
      <S.Container
        {...attributes}
        layerId={`${propertyKey}=${propertyValue}`}
        editing={Builder.isEditing}
      >
        {children || noChildren()}
      </S.Container>
    );
  };

  if (show || Builder.isEditing) {
    return <Container />;
  } else {
    return ``;
  }
};

export default AnswerContainer;
